<template>
  <section id="banner" class="page">
    <banner-image-overlay :fullscreen="true"></banner-image-overlay>
    <div class="main-container">
      <h2 class="title">Update Password</h2>
      <div class="d-flex justify-content-center">
        <article>
          <form v-on:submit.prevent="onSubmit">
            <div class="form-group">
              <input name="new-password" class="form-control"
                type="password" minlength="8" maxlength="20"
                placeholder="New Password" required
                v-model="newPassword">
            </div>
            <div class="form-group">
              <input name="re-new-password" class="form-control"
                type="password" minlength="8" maxlength="20"
                placeholder="Re-type New Password" required
                v-model="reNewPassword">
            </div>
            <div class="alert alert-danger align-left" v-show="error">{{ error }}</div>
            <div class="alert alert-success align-left" v-show="success">{{ success }}</div>
            <div class="row">
              <div class="col-md-12">
                <div class="form-group">
                  <button type="submit" class="button special">Update</button>
                </div>
              </div>
            </div>
          </form>
        </article>
      </div>
    </div>
  </section>
</template>

<script>
import BannerImageOverlay from '../../components/common/utils/BannerImageOverlay.vue';
import UserApi from '../../api/User';

export default {
  name: 'ResetPasswordConfirmation',
  components: {
    BannerImageOverlay,
  },
  data() {
    return {
      newPassword: '',
      reNewPassword: '',
      error: '',
      success: '',
    };
  },
  methods: {
    onSubmit() {
      this.error = '';
      this.success = '';

      if (this.newPassword !== this.reNewPassword) {
        document.getElementById('newPassword').setCustomValidity('Passwords don\'t match');
        return;
      }
      const urlParams = new URLSearchParams(window.location.search);
      const token = urlParams.get('token');
      if (!token) {
        this.error = 'Missing reset password confirmation token. Make sure that the webpage is correct';
        return;
      }

      UserApi
        .resetPasswordConfirmation(this.newPassword, token)
        .then((response) => {
          this.success = response.data.success;
          this.newPassword = '';
          this.reNewPassword = '';
        })
        .catch((error) => {
          this.error = this.parseError(error, 'Unable to register, please try later');
        });
    },
  },
};
</script>

<style scoped>
  strong, b {
    color: #B6B6B6;
  }

  article {
    width: 500px;
  }

  a {
    text-decoration: none;
  }

  a:hover {
    color: #38AAF0;
  }

  button[type=submit] {
    float: left;
  }

  #banner {
    height: 100%;
    padding-top: 22vh;
  }

  @media screen and (max-width: 736px) {
    #banner {
      padding-left: 0;
      padding-right: 0;
    }

    .main-container {
      padding-left: 25px;
      padding-right: 25px;
    }
  }

  @media screen and (max-width: 480px) {
    input[type="submit"],
    input[type="reset"],
    input[type="button"],
    button,
    .button {
        padding: 0 2.2em;
    }
  }
</style>
